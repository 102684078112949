.footer {
  background-color: rgba(0, 0, 0, 0.596);
  position: fixed;
  bottom: 0%;
}

.icons {
  justify-content: center;
  align-items: center;
  border: black 2px solid;
}