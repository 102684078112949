@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jumbotron {
  background-color: #ebf3fe;
}
.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid black; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  -webkit-animation: typing 2.5s steps(40, end), blink-caret 0.75s step-end infinite;
          animation: typing 2.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@-webkit-keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@-webkit-keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(240, 101, 9);
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(240, 101, 9);
  }
}
.welcome {
  font-size: 3em;

  text-align: center;
}
.wtext {
  font-size: 2.5em;
}

.home-border {
  border: solid 7px #496d83;
}

@media screen and (max-width: 1200px) {
  .welcome {
    font-size: 2em;
  }
}

@media screen and (max-width: 770px) {
  .welcome {
    font-size: 1.25em;
  }

  .home-border {
    border: solid 5px black;
  }
}

@media screen and (max-width: 450px) {
  .welcome {
    font-size: 1em;
  }

  .home-border {
    border: solid 5px black;
  }
}

@media screen and (min-width: 450px) {
  .containerflex {
    height: 100vh;
  }
}

.background-adjustment {
  height: 100vh;
}

.borderc {
  border: #496d83 2px solid;
}
.skill-list {
  list-style-type: none;
}
.about-border {
  border: solid 2px #496d83;
  border-radius: 7px;
}


.imgProp {
  max-width: 300px;
  max-height: 200px;
}
.cards {
  background-color: #ebf3fe;
}
.bordercustom {
  border: black 1px solid;
  border-radius: 17px;
}

.projtitle {
  font-size: 1.5em;
  justify-content: center;
  padding: 10px;
}
.projDescription {
  font-size: 1em;
  margin-top: 10px;
}

.responsive-width {
  width: 50%;
}

@media screen and (max-width: 450px) {
  .responsive-width {
    width: 100%;
  }
}

.height {
  height: 100vh;
}

.header {
  background: #496d83;

  border: #6d2f2f 1px solid;
}
.navbrand {
  margin-left: 2%;
 
}

.navcss {
  color: white;
  font-size: 1em;
  text-decoration: none;
}
@media screen and (min-width: 450px) {
  .containerflex {
    height: 100vh;
  }
  .resume-link {
    position: relative;
    left: 35%;
  }
}
@media screen and (max-width: 450px) {
  .containerflex {
    height: 100vh;
  }
  .resume-body {
    background-color: white;
    max-width: 400px;
    overflow: scroll;
  }
  .resume-link {
    display: none;
  }
}

.footer {
  background-color: rgba(0, 0, 0, 0.596);
  position: fixed;
  bottom: 0%;
}

.icons {
  justify-content: center;
  align-items: center;
  border: black 2px solid;
}
#root {
  font-family: "Oswald", sans-serif;
}
.main {
  background-image: url(/static/media/background.57fc0f35.png);
  background-size: cover;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center center;
  height: auto;
}

