.responsive-width {
  width: 50%;
}

@media screen and (max-width: 450px) {
  .responsive-width {
    width: 100%;
  }
}

.height {
  height: 100vh;
}
