@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

#root {
  font-family: "Oswald", sans-serif;
}
.main {
  background-image: url("./components/background/background.png");
  background-size: cover;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center center;
  height: auto;
}
