@media screen and (min-width: 450px) {
  .containerflex {
    height: 100vh;
  }
  .resume-link {
    position: relative;
    left: 35%;
  }
}
@media screen and (max-width: 450px) {
  .containerflex {
    height: 100vh;
  }
  .resume-body {
    background-color: white;
    max-width: 400px;
    overflow: scroll;
  }
  .resume-link {
    display: none;
  }
}
